import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { rgbToCmyk } from '../colourTools'

// import cmykIcon from "../images/cmyk.svg";

const ColourBlock = ({ colour }) => {
  const { red, green, blue } = colour.colourValue
  const cmyk = rgbToCmyk(red, green, blue)

  return (
    <div
      key={colour.id}
      className="br3 mr3 bg-white xba dark-gray b--black-10 mb4 w-100 w-50-m w-33-l"
    >
      <div
        className="br3 ma2 ba b--black-10"
        style={{
          backgroundColor: colour.colourValue.hex,
          height: '150px',
        }}
      />
      <div className="pa3">
        <h3 className="fw5 f5 f4-ns mb0 mt2">{colour.name}</h3>
        <ul className="pl0 mt2 list black-60">
          {cmyk && (
            <li className="bt b--black-10 pv2">
              {`${cmyk.c}C, ${cmyk.m}M, ${cmyk.y}Y, ${cmyk.k}K`}
            </li>
          )}
          {colour.colourValue && (
            <li className="bt b--black-10 pv2">
              {colour.colourValue.hex} ({red}, {green}, {blue})
            </li>
          )}
          {colour.pantone && (
            <li className="bt b--black-10 pv2">Pantone {colour.pantone}</li>
          )}
        </ul>
      </div>
    </div>
  )
}

const ColoursPage = ({ data }) => {
  const primaryColours = data.allDatoCmsColour.edges.filter(
    x => x.node.category == 'Primary'
  )
  const secondaryColours = data.allDatoCmsColour.edges.filter(
    x => x.node.category == 'Secondary'
  )

  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsColoursPage.seoMetaTags} />

      <div className="mw8-l center ph3 ph0-l mt4 mt5-l">
        <h1 className="text-h1">{data.datoCmsColoursPage.title}</h1>

        <div
          className="mw7"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsColoursPage.introNode.childMarkdownRemark.html,
          }}
        />
      </div>

      <section className="mw8-l center ph3 ph0-l mt5">
        <h2 id="primary-colours">Primary colours</h2>

        <div
          className="mw7"
          dangerouslySetInnerHTML={{
            __html:
              data.datoCmsColoursPage.primaryColoursDescriptionNode
                .childMarkdownRemark.html,
          }}
        />

        <div className="flex mt4">
          {primaryColours.map(({ node: colour }) => (
            <ColourBlock colour={colour} />
          ))}
        </div>
      </section>

      <section className="mw8-l center ph3 ph0-l mt5 mb5">
        <h2 id="secondary-colours">Secondary colours</h2>

        <div
          className="mw7"
          dangerouslySetInnerHTML={{
            __html:
              data.datoCmsColoursPage.secondaryColoursDescriptionNode
                .childMarkdownRemark.html,
          }}
        />

        <div className="flex mt4">
          {secondaryColours.map(({ node: colour }) => (
            <ColourBlock colour={colour} />
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default ColoursPage

export const query = graphql`
  query ColoursQuery {
    datoCmsSite {
      globalSeo {
        siteName
      }
    }
    datoCmsColoursPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      introNode {
        childMarkdownRemark {
          html
        }
      }
      primaryColoursDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      secondaryColoursDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
    }
    allDatoCmsColour {
      edges {
        node {
          id
          name
          category
          pantone
          colourValue {
            red
            green
            blue
            alpha
            rgb
            hex
          }
        }
      }
    }
  }
`
