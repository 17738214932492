import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import '../styles/index.scss'

const menuClass = 'white-80 fw5 f6 f5-l link dib pt3 pr3 pb3 pr4-l'

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        datoCmsSite {
          globalSeo {
            siteName
          }
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
        datoCmsBrandPage {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          title
        }
      }
    `}
    render={data => (
      <div>
        <HelmetDatoCms
          favicon={data.datoCmsSite.faviconMetaTags}
          seo={data.datoCmsBrandPage.seoMetaTags}
        />

        <header>
          <nav className="bg-black">
            <div className="mw8-l center ph3 ph0-l">
              <Link to="/colours" className={menuClass} activeClassName="white">
                Colours
              </Link>
              <Link to="/logos" className={menuClass} activeClassName="white">
                Logos
              </Link>
              <a className={menuClass} href="/">
                Typography
              </a>
            </div>
          </nav>
        </header>

        {children}
      </div>
    )}
  />
)

TemplateWrapper.propTypes = {
  children: PropTypes.object,
}

export default TemplateWrapper
